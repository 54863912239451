import React from 'react';

export default function CapHeader({ text, styleName }) {
  return (
    <h3 className={`h--medium ${styleName || ''}`} style={{ zIndex: '10' }}>
      <span style={{ fontSize: '2em' }}>{text.slice(0, 1).toUpperCase()}</span>
      {text.slice(1)}
    </h3>
  );
}
