import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import InView from 'react-intersection-observer';
import CapHeader from './CapHeader';
import instaIcon from '../assets/images/icons/instagram.svg';
import {
  INSTAGRAM_PROFILE_NAME,
  INSTAGRAM_URL,
  YOUTBE_EMBED_BASE,
} from '../utils/constants';

const BlogStyles = styled.section`
  background-color: var(--beige);
  color: var(--black);
  padding: 10rem 0 2rem;
  position: relative;
  overflow: hidden;

  @media (max-width: 900px) {
    padding: 8rem 0 2rem;
  }

  .blog__header {
    margin: 0 6rem;
  }
  .channel {
    padding: 0 12rem 2rem;
    z-index: 10;
    position: relative;

    @media (max-width: 900px) {
      padding: 6rem 6rem 2rem;
    }
    @media (max-width: 600px) {
      padding: 4rem 6rem 0;
    }
  }
  .channel__background {
    font-size: 25rem;
    color: var(--grey);
    position: absolute;
    bottom: -6rem;
    width: 100%;
    line-height: 1em;
    overflow: hidden;
    @media (max-width: 900px) {
      font-size: 18rem;
      bottom: -4.2rem;
    }
    @media (max-width: 600px) {
      font-size: 12rem;
      bottom: -2.9rem;
    }
    @media (max-width: 500px) {
      font-size: 10rem;
      bottom: -2.4rem;
    }
  }
  .channel__header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1.2rem;
  }
  .channel__link,
  .channel__link:visited {
    text-decoration: none;
    color: var(--black);
    font-size: 1.4rem;
    margin-top: 0.6rem;
    margin-left: 0.4rem;
    z-index: 10; // on top of Youtube bg
    position: relative;
  }
  .channel__link:hover {
    color: var(--red);
    &:after {
      opacity: 1;
    }
  }
  .channel__link:after {
    content: '';
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 1px;
    border-width: 0 0 1px;
    border-style: solid;
    opacity: 0;
  }
  .channel__description {
    margin-top: 1rem;
    font-size: 1.2rem;
  }
  .channel__video-list {
    display: grid;
    gap: 1.6rem;
    grid-template-columns: repeat(3, 1fr);

    @media (max-width: 600px) {
      grid-template-columns: 1fr; /* stack video as 1 col */
    }
  }
  .video-wrapper {
    position: relative; /* responsive video container while keeping its ratio */
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
  }
  .video-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .posts {
    margin: 0 16rem;

    @media (max-width: 1200px) {
      margin: 0 4rem;
    }

    @media (max-width: 600px) {
      margin: 0 2rem;
    }

    &.is-visible {
      li:nth-of-type(odd) {
        animation-name: moveInUp;
        animation-duration: 1s;
        animation-timing-function: ease-out;
      }
      li:nth-of-type(even) {
        animation-name: moveInUp;
        animation-duration: 1s;
        animation-timing-function: ease-in;
      }
    }
  }
  .post__list {
    display: grid;
    --columns: 5;
    grid-template-columns: repeat(var(--columns), minmax(auto, 1fr));
    row-gap: 3rem;
    justify-items: center;
    align-items: center;

    .post__list-item {
      max-width: 150px;
      margin: 0 1rem;

      @media (min-width: 1680px) {
        max-width: 220px;
      }
    }

    @media (max-width: 600px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .post__list-item {
        @media (max-width: 500px) {
          flex: 0 1 calc(50% - 3rem);
          text-align: center;
        }
      }
    }
  }
`;

const renderPosts = (posts) => (
  <InView triggerOnce>
    {({ inView, ref }) => (
      <div ref={ref} className={`posts ${inView ? 'is-visible' : ''}`}>
        <h4 className="channel__header">Check out latest instagram</h4>
        <h5 className="channel__header">
          <img src={instaIcon} alt={INSTAGRAM_PROFILE_NAME} />
          <a href={INSTAGRAM_URL} className="channel__link h--bold">
            {INSTAGRAM_PROFILE_NAME} &rarr;
          </a>
        </h5>
        <ul className="post__list">
          {posts.map(({ url, href, id, caption }) => (
            <li key={id} className="post__list-item">
              <a href={href}>
                <img src={url} alt={caption} />
              </a>
            </li>
          ))}
        </ul>
      </div>
    )}
  </InView>
);
const renderChannel = ({ name, description, link, image, videos }) => (
  <div className="channel">
    <h4 className="channel__header">Check out more videos</h4>
    <h5 className="channel__header">
      <Img fixed={image.asset.fixed} alt={name} />
      <a href={link} className="channel__link h--bold">
        {name} &rarr;
      </a>
    </h5>
    <ul className="channel__video-list">
      {videos.map(({ name, link, id }) => (
        <li key={id} className="video-wrapper">
          <iframe
            title={name}
            width="360"
            height="200"
            src={`${YOUTBE_EMBED_BASE}/${link}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </li>
      ))}
    </ul>
    <p className="channel__description p--light">{description}</p>
  </div>
);
export default function Blog({ channel, ins }) {
  return (
    <BlogStyles id="blog">
      <CapHeader text="Personal blogs" styleName="blog__header" />
      {/* {ins && renderPosts(ins)} */}
      {renderChannel(channel)}
      <p className="channel__background h--medium">Youtube</p>
    </BlogStyles>
  );
}
