import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Experience from '../components/Experience';
import Hero from '../components/Hero';
import Work from '../components/Work';
import Design from '../components/Design';
import Illustration from '../components/Illustration';
import Blog from '../components/Blog';
import { SCHEMA_FIELD, extractData, transformInsData } from '../utils/helper';
import SEO from '../components/SEO';

const HomePageStyles = styled.div`
  scroll-behavior: smooth;
`;
const HomePage = ({ data }) => {
  const [person] = extractData(data, SCHEMA_FIELD.PERSONS);
  const { pic, illustration } = person;
  const jobs = extractData(data, SCHEMA_FIELD.JOBS);
  const designs = extractData(data, SCHEMA_FIELD.DESIGNS);
  const illustrations = extractData(data, SCHEMA_FIELD.ILLUSTRATIONS);
  const [channel] = extractData(data, SCHEMA_FIELD.CHANNELS); // get the first and only one
  const ins = transformInsData(data);
  return (
    <>
      <SEO title="C / D" />
      <HomePageStyles>
        <Hero image={pic} />
        <Experience image={illustration} />
        <Work jobs={jobs} />
        <Design designs={designs} />
        <Illustration illustrations={illustrations} />
        <Blog channel={channel} ins={ins} />
      </HomePageStyles>
    </>
  );
};
export default HomePage;

export const query = graphql`
  query {
    persons: allSanityPerson {
      edges {
        node {
          name
          pic {
            asset {
              fluid(maxWidth: 800) {
                ...GatsbySanityImageFluid
              }
            }
          }
          illustration {
            asset {
              fluid(maxWidth: 300) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
    jobs: allSanityJob {
      edges {
        node {
          name
          id
          time
          period
          slug {
            current
          }
          image {
            asset {
              fluid(maxWidth: 1000) {
                ...GatsbySanityImageFluid
              }
            }
          }
          teams {
            id
            name
            descriptions
            image {
              asset {
                fixed(width: 24, height: 24) {
                  ...GatsbySanityImageFixed
                }
              }
            }
          }
        }
      }
    }
    designs: allSanityDesign(sort: { fields: time, order: DESC }) {
      edges {
        node {
          name
          id
          slug {
            current
          }
          time
          description
          image {
            asset {
              fluid(maxWidth: 1000) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
    illustrations: allSanityIllustration(
      sort: { fields: sequence, order: ASC }
    ) {
      edges {
        node {
          name
          id
          slug {
            current
          }
          sequence
          image {
            asset {
              fluid(maxWidth: 500) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
    channels: allSanityChannel {
      edges {
        node {
          name
          id
          slug {
            current
          }
          link
          description
          image {
            asset {
              fixed(width: 24, height: 24) {
                ...GatsbySanityImageFixed
              }
            }
          }
          videos {
            id
            name
            link
            image {
              asset {
                fluid(maxWidth: 500) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
      }
    }
    ins: allIns(limit: 15) {
      edges {
        node {
          id
          media_url
          caption
          permalink
        }
      }
    }
  }
`;
