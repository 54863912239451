import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';

const HeroStyles = styled.section`
  background: var(--beige);
  padding: 5rem 18rem 12rem;
  overflow: hidden;

  display: grid;
  grid-template-columns: 3fr 3fr 4fr;
  column-gap: 7rem;
  align-items: end;
  justify-items: end;

  @media (max-width: 900px) {
    padding: 4rem 8rem 12rem;
    column-gap: 4rem;
  }

  @media (max-width: 600px) {
    padding: 4rem 6rem 6rem;
    grid-template-columns: 1fr 1fr; // stack column
    column-gap: 2rem;
    row-gap: 5rem;
    justify-items: left;
  }

  @media (max-width: 500px) {
    padding: 4rem 4rem 6rem;
  }

  .self-intro {
    display: grid;
    grid-template-rows: 2.5fr minmax(min-content, 1fr);

    @media (max-width: 800px) {
      grid-template-rows: 1.5fr 1fr;
      gap: 4rem;
    }
    @media (max-width: 600px) {
      column-gap: 7rem;
      grid-template-rows: 1fr;
      align-items: left;
    }
    @media (max-width: 500px) {
      .self-intro__header {
        font-size: 2rem;
      }
      .self-intro__name {
        font-size: 2.4rem;
      }
    }
    animation-name: moveInLeft;
    animation-duration: 1.2s;
    animation-timing-function: ease-out;
  }

  .profile-pic {
    width: 100%;
    max-width: 25rem; /* let image scale with window width */
    justify-self: center;
    border: 1px solid transparent;
    border-radius: 1rem;

    animation-name: moveInBottom;
    animation-duration: 1s;
    animation-timing-function: ease-out;

    @media (max-width: 500px) {
      max-width: 20rem;
    }
  }

  .my-story {
    @media (max-width: 600px) {
      grid-column: span 2;
    }
  }
  .my-story__text {
    font-size: 1.4rem;
    margin-top: 1rem;
    animation-name: moveInRight;
    animation-duration: 1.2s;
    animation-timing-function: ease-out;
  }
`;
export default function Hero({ image }) {
  return (
    <HeroStyles>
      <div className="self-intro">
        <h1 className="self-intro__header">
          <span style={{ fontSize: '5em' }}>H</span>
          <span style={{ fontSize: '2em' }} className="h--medium">
            i!
          </span>
        </h1>
        <div>
          <h3 className="self-intro__name h--bold">Coco Duan</h3>
          <p>Front-end engineer</p>
          <p>Freelance UI designer</p>
          <p>Blogger</p>
        </div>
      </div>
      {/* <img src={`${profilePic}`} alt="Profile" className="profile-pic" /> */}
      <Img
        className="profile-pic"
        fluid={image.asset.fluid}
        alt="Profile picture"
      />
      <div className="my-story">
        <h3 className="h--bold">My</h3>
        <h3 className="h--bold">Story:</h3>
        <p className="my-story__text">
          I’m Coco Duan, a <span className="p--bold">front-end engineer</span>{' '}
          with <span className="p--bold">5+ years</span> of professional
          experience writing web applications at{' '}
          <span className="p--bold">LinkedIn</span> and{' '}
          <span className="p--bold">AppDynamics</span>. Beyond that, I have a
          strong passion about <span className="p--bold">UX/UI design</span> and
          digital drawing. In my free time, I love to explore new places and
          blog about traveling on Instagram and Youtube.
        </p>
      </div>
    </HeroStyles>
  );
}
