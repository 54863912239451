import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import CapHeader from './CapHeader';

const DesignStyles = styled.section`
  background-color: var(--black--0);
  color: var(--white);
  padding: 10rem 6rem 14rem;

  @media (max-width: 900px) {
    padding: 8rem 5rem 10rem;
  }
  @media (max-width: 600px) {
    padding: 8rem 4rem 8rem;
  }

  .design__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;

    @media (max-width: 600px) {
      grid-template-columns: 1fr;
    }
  }
  .design__list-item {
    background-color: var(--white);
    color: var(--black);
    height: 60rem;
    overflow: hidden;

    @media (max-width: 900px) {
      height: 45rem;
    }
    @media (min-width: 2000px) {
      height: 70rem;
    }
  }

  .design__list-item-link {
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
    text-decoration: none;

    * {
      cursor: pointer;
    }

    &,
    &:visited,
    &:hover {
      color: inherit;
    }

    &::before {
      background: rgba(15, 20, 30, 0.9);
      position: absolute;
      display: block;
      content: '';
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0;
      z-index: 2;
      transition: opacity 0.2s linear;
    }

    &:hover::before {
      opacity: 1;
    }

    &:hover .design__header {
      &.regular {
        opacity: 0;
      }
      &.action {
        opacity: 1;
        animation: 0.4s ease-out moveInBottom;
      }
    }
    &:hover .design__action-link-text {
      margin-top: 2rem;
      opacity: 0.7;
      transform: translate3d(0, 0, 0);
      transition: all 0.2s linear 0.4s; // after header animation finishes
    }

    &:hover .design__image {
      transform: scale(1.05);
      transition: transform 0.4s;
    }
  }

  .design__image {
    position: absolute;
    width: 100%;
    min-width: 240px;
    bottom: 0;
    right: 0;
    transform: scale(1);

    @media (max-width: 600px) {
      right: -10%;
    }
  }
  .design__header {
    padding: 5rem 5rem 0;
    z-index: 3;
    position: absolute;
    width: 100%;

    @media (max-width: 600px) {
      width: 50%;
    }
    @media (max-width: 500px) {
      width: 100%;
    }

    &.regular {
      opacity: 1;
    }
    &.action {
      opacity: 0;
      color: var(--white);

      .design__desc {
        background: transparent; /* action menu bg is always transparent */
      }
    }
  }
  .design__header--white {
    color: white;
  }

  .design__desc {
    margin-top: 0.8rem;

    @media (max-width: 600px) {
      background-image: linear-gradient(
        to right,
        transparent,
        rgba(255, 255, 255, 0.6)
      );
    }
    @media (max-width: 500px) {
      background: transparent;
    }
  }

  .design__action-link-text {
    margin-top: 1.2rem;
    opacity: 0;
    transform: translate3d(0, 2rem, 0);
    &:hover {
      text-decoration: underline;
    }
  }

  .design__background--brown {
    background-color: var(--brown--dark);
  }
  .design__background--olive {
    background-color: var(--olive);
  }
  .design__background--champaign {
    background-color: var(--champaign);
  }

  .design__background--brown,
  .design__background--olive {
    .design__desc {
      @media (max-width: 600px) {
        background-image: linear-gradient(
          to right,
          transparent,
          rgba(0, 0, 0, 0.6)
        );
      }
      @media (min-width: 2000px) {
        background-image: linear-gradient(
          to left,
          transparent,
          rgba(0, 0, 0, 0.5)
        );
      }
      @media (max-width: 500px) {
        background: transparent;
      }
    }
  }
`;
const cardBackgroundColor = (name) => {
  if (name.toLowerCase().includes('wayfarer')) {
    return 'design__background--brown';
  }
  if (name.toLowerCase().includes('modura')) {
    return 'design__background--olive';
  }
  return '';
};
const headerColor = (name) => {
  if (
    name.toLowerCase().includes('wayfarer') ||
    name.toLowerCase().includes('modura')
  ) {
    return 'design__header--white';
  }
  return '';
};
const renderDesigns = (designs) => (
  <ul className="design__list">
    {designs.map(({ id, slug, name, description, image }) => (
      <li key={id} className={`design__list-item ${cardBackgroundColor(name)}`}>
        <Link to={`/design/${slug.current}`} className="design__list-item-link">
          <div className={`regular design__header ${headerColor(name)}`}>
            <h3 className="h--bold">{name}</h3>
            <h4 className="design__desc">{description}</h4>
          </div>
          <div className={`action design__header ${headerColor(name)}`}>
            <h3 className="h--bold">{name}</h3>
            <h4 className="design__desc">{description}</h4>
            <h5 className="p--light design__action-link-text">
              View details &rarr;
            </h5>
          </div>
          <div className="design__image">
            <Img
              fluid={image.asset.fluid}
              imgStyle={{ objectPosition: '50% 1rem' }}
              alt={name}
            />
          </div>
        </Link>
      </li>
    ))}
  </ul>
);
export default function Design({ designs }) {
  return (
    <DesignStyles id="design">
      <CapHeader text="design" />
      {renderDesigns(designs)}
    </DesignStyles>
  );
}
