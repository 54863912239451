import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import iconCode from '../assets/images/icons/code.svg';
import iconDesign from '../assets/images/icons/design.svg';
import CapHeader from './CapHeader';

const ExperienceStyles = styled.section`
  background: rgba(0, 0, 0, 0.8);
  color: var(--white);
  padding: 8rem 18rem;
  position: relative;

  @media (max-width: 900px) {
    padding: 6rem 8rem;
  }
  @media (max-width: 600px) {
    padding: 4rem;
  }

  .illustration-girl {
    position: absolute;
    top: -14rem;
    left: -2rem;
    width: 20rem;

    @media (max-width: 600px) {
      top: -6rem;
      width: 12rem;
    }
  }
  .grid {
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: center;

    @media (max-width: 600px) {
      grid-template-columns: 1fr 2fr;
      justify-items: end;
    }
  }

  li {
    display: grid;
    grid-template-columns: 4rem minmax(min-content, 1fr);
    align-items: start;

    &:not(:last-child) {
      margin-bottom: 4rem;
    }

    .content {
      grid-column: 2 / 2;
    }
  }

  .icon {
    display: inline-block;
    width: 2rem;
    height: 2rem;
  }
  .icon--code {
    background: url(${iconCode});
    background-size: cover;
  }
  .icon--design {
    background: url(${iconDesign});
    background-size: cover;
  }
`;
export default function Experience({ image }) {
  return (
    <ExperienceStyles>
      <div className="grid">
        <CapHeader text="experience" />
        <ul>
          <li>
            <i className="icon icon--code" />
            <h4 className="h--medium">Front-end engineer</h4>
            <div className="content">
              <p>LinkedIn</p>
              <p>AppDynamics</p>
            </div>
          </li>
          <li>
            <i className="icon icon--design" />
            <h4 className="h--medium">UI designer</h4>
            <div className="content">
              <p>Freelance</p>
            </div>
          </li>
        </ul>
      </div>
      <div className="illustration-girl">
        <Img fluid={image.asset.fluid} alt="Girl illustration" />
      </div>
    </ExperienceStyles>
  );
}
