import { INSTA_PHOTO_SIZE } from './constants';

export const SCHEMA_FIELD = {
  PERSONS: 'persons',
  JOBS: 'jobs',
  DESIGNS: 'designs',
  ILLUSTRATIONS: 'illustrations',
  CHANNELS: 'channels',
  INS: 'ins',
};
export const extractData = (data, field) => {
  const { edges } = data[field];
  return edges.map((edge) => edge.node);
};
// Format ins data to display in Blog component
export const transformInsData = (ins) =>
  extractData(ins, SCHEMA_FIELD.INS).map(
    ({ id, media_url: mediaUrl, caption, permalink }) => {
      // post link
      const href = `${permalink}`;
      // image src
      const url = `${mediaUrl}`;
      return {
        url,
        href,
        id,
        caption,
      };
    }
  );
