import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { InView } from 'react-intersection-observer';
import CapHeader from './CapHeader';

const IllustrationStyles = styled.section`
  background-color: var(--charcoal);
  color: var(--white);
  position: relative;

  .header {
    letter-spacing: 0.4rem;
    position: absolute;
    transform: translate(0, -2rem) rotate(90deg);

    @media (max-width: 600px) {
      font-size: 2.4rem;
    }
  }

  .illustration__list {
    margin: 0 6rem;
    display: grid;
    grid-template-columns: 3.5fr 3fr 3fr 3fr;
    height: 75rem;
    column-gap: 3rem;
    overflow: hidden;

    @media (max-width: 900px) {
      margin: 0;
      height: 70rem;
      column-gap: 0;
    }
    @media (max-width: 800px) {
      margin: 0;
      height: 60rem;
      column-gap: 0;
    }
    @media (max-width: 600px) {
      margin: 0 3rem;
      grid-template-columns: 1fr 1fr;
      gap: 3rem;
      height: 100%;
    }

    &.is-visible {
      li:nth-of-type(odd) {
        animation-name: slideInBottom;
        animation-duration: 1s;
        animation-timing-function: ease-out;
      }
      li:nth-of-type(even) {
        animation-name: slideInUp;
        animation-duration: 1s;
        animation-timing-function: ease-out;
      }
    }
    li {
      position: relative;
    }
    li:nth-child(1) {
      align-self: end;
      grid-column: 1;
      @media (max-width: 600px) {
        margin-top: 14rem;
      }
      .illustration__image {
        height: 55rem;
        width: 100%;
        @media (max-width: 900px) {
          height: auto;
        }
      }
      .label {
        position: absolute;
        top: -3rem;
        left: 2rem;
      }
    }
    li:nth-child(2) {
      align-self: start;
      grid-column: 2;
      .illustration__image {
        height: 45rem;
        width: 100%;
        @media (max-width: 900px) {
          height: auto;
        }
      }
      .label {
        position: absolute;
        top: 6rem;
        left: -5rem;
        transform: rotate(90deg);
        @media (max-width: 600px) {
          top: 100%;
          left: calc(100% - 8rem);
          transform: rotate(0deg);
        }
      }
    }
    li:nth-child(3) {
      align-self: start;
      grid-column: 3;
      @media (max-width: 600px) {
        grid-column: 1;
        align-self: end;
        margin-top: 2rem;
      }
      .illustration__image {
        height: 30rem;
        width: 100%;
      }
      .label {
        position: absolute;
        top: 8rem;
        right: -8rem;
        transform: rotate(90deg);
        @media (max-width: 600px) {
          top: -3rem;
          right: 0;
          transform: rotate(0deg);
        }
      }
    }
    li:nth-child(4) {
      align-self: end;
      grid-column: 4;
      @media (max-width: 600px) {
        grid-column: 2;
      }
      .illustration__image {
        height: 45rem;
        width: 100%;
        @media (max-width: 900px) {
          height: auto;
        }
      }
      .label {
        position: absolute;
        top: -3rem;
        right: 1rem;
      }
    }
    .illustration__title {
      position: absolute;
      font-size: 3.6rem;
      letter-spacing: 0.4rem;
      top: 70%;
      left: 55%;

      @media (max-width: 900px) {
        font-size: 3rem;
      }
      @media (max-width: 600px) {
        font-size: 2.4rem;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  @keyframes slideInUp {
    0% {
      opacity: 0;
      transform: translateY(-10rem);
    }
    100% {
      opacity: 1;
      transform: translate(0);
    }
  }
  @keyframes slideInBottom {
    0% {
      opacity: 0;
      transform: translateY(10rem);
    }
    100% {
      opacity: 1;
      transform: translate(0);
    }
  }
`;
const renderIllustrations = (illustrations) => (
  <InView triggerOnce>
    {({ inView, ref }) => (
      <ul
        ref={ref}
        className={`illustration__list ${inView ? 'is-visible' : ''}`}
      >
        {illustrations.map(({ id, name, image }) => (
          <li key={id}>
            <p className="label">{name}</p>
            <Img
              className="illustration__image"
              fluid={image.asset.fluid}
              alt={name}
            />
          </li>
        ))}
        <li className="illustration__title h--medium">Illustrations</li>
      </ul>
    )}
  </InView>
);
export default function Illustration({ illustrations }) {
  return (
    <IllustrationStyles>
      <CapHeader styleName="header" text="visual" />
      {renderIllustrations(illustrations)}
    </IllustrationStyles>
  );
}
