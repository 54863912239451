import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { InView } from 'react-intersection-observer';
import CapHeader from './CapHeader';

const WorkStyles = styled.section`
  background: var(--white);
  color: var(--black);
  padding: 10rem 18rem;
  overflow: hidden;

  @media (max-width: 900px) {
    padding: 8rem;
  }

  @media (max-width: 600px) {
    padding: 8rem 4rem 3rem;
  }

  .job__list-item:not(:last-child) {
    margin-bottom: 8rem;

    @media (max-width: 600px) {
      margin-bottom: 4rem;
    }
  }
  .job__list-item {
    @media (max-width: 900px) {
      .job__image.is-visible,
      &:nth-of-type(odd) .job__image.is-visible,
      &:nth-of-type(even) .job__image.is-visible {
        animation: none;
      }
    }
    .job__image.is-visible {
      animation-duration: 1.2s;
    }
    &:nth-of-type(odd) .job__image.is-visible {
      animation-name: moveInRight;
      animation-timing-function: ease-out;
    }
    &:nth-of-type(even) .job__image.is-visible {
      animation-name: moveInLeft;
      animation-timing-function: ease-in;
    }
  }
`;

const JobStyles = styled.section`
  display: grid;
  grid-template-columns: 4fr 1fr 7fr;
  grid-template-rows: repeat(2, min-content) 1fr;
  gap: 3rem;

  .job__company {
    grid-column: 3 / 3;
    display: flex;
    justify-content: space-between;
    letter-spacing: 1.2px;

    @media (max-width: 600px) {
      grid-column: 1 / -1;
    }
  }
  .job__period {
    grid-column: 2 / 2;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: min-content 1fr;
    justify-items: center;

    @media (max-width: 600px) {
      display: none;
    }
  }
  .job__period-time {
    margin-top: 2rem;
  }
  .job__period-divider {
    margin-top: 3rem;
    height: 100%;
    border-left: 1px solid var(--grey--light);
  }
  .job__desc {
    grid-column: 3 / 3;

    @media (max-width: 600px) {
      grid-column: 1 / -1;
    }

    li:not(:last-child) {
      margin-bottom: 2rem;
    }
  }
  .job__image {
    grid-column: 1 / -1;
    grid-row: 3 / 3;
    justify-self: flex-end;
    width: 80%;
    height: 52rem;
    overflow: hidden;

    @media (max-width: 900px) {
      width: 95%;
      height: 48rem;
    }
    @media (max-width: 800px) {
      width: 95%;
      height: 30rem;
    }
    /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); */
  }
  .team__header {
    font-size: 1.4rem;
    margin-bottom: 0.8rem;
    display: flex;
    align-items: center;
    gap: 0.8rem;
  }
`;
const renderTeams = (teams) =>
  teams.map(({ id, name, descriptions, image }) => (
    <li key={id}>
      <h5 className="p--medium team__header">
        <Img fixed={image.asset.fixed} alt={name} />
        {name}
      </h5>
      <p>{descriptions}</p>
    </li>
  ));
const Job = function ({ company, time, teams, period, image }) {
  return (
    <JobStyles>
      <h5 className="h--bold job__company">
        <p>{company}</p>
        <p>{time}</p>
      </h5>
      <div className="job__period">
        <h4 className="job__period-time">{period}</h4>
        <div className="job__period-divider" />
      </div>
      <ul className="job__desc">{renderTeams(teams)}</ul>
      <InView triggerOnce>
        {({ inView, ref }) => (
          <div ref={ref} className={`job__image ${inView ? 'is-visible' : ''}`}>
            <Img fluid={image.asset.fluid} alt={company} />
          </div>
        )}
      </InView>
    </JobStyles>
  );
};
const renderJobs = (jobs) =>
  jobs
    .sort((a, b) => (a.time < b.time ? 1 : -1))
    .map(({ id, name, period, teams, time, image }) => (
      <li key={id} className="job__list-item">
        <Job
          company={name}
          time={time}
          period={period}
          image={image}
          teams={teams}
        />
      </li>
    ));
export default function Work({ jobs }) {
  return (
    <WorkStyles id="work">
      <CapHeader text="work" />
      <ul>{renderJobs(jobs)}</ul>
    </WorkStyles>
  );
}
